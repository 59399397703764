const drentseMuseaMapStyles = [
    {
        elementType: 'geometry',
        stylers: [{ color: '#f5f5f5' }]
    },
    {
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }]
    },
    {
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }]
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#f5f5f5' }]
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#bdbdbd' }]
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }]
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }]
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }]
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#757575' }]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#dadada' }]
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#616161' }]
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }]
    },
    {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{ color: '#e5e5e5' }]
    },
    {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{ color: '#eeeeee' }]
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#c9c9c9' }]
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9e9e9e' }]
    }];

var domIsReady = (function (domIsReady) {
    var isBrowserIeOrNot = function () {
        return (!document.attachEvent || typeof document.attachEvent === "undefined" ? 'not-ie' : 'ie');
    }

    domIsReady = function (callback) {
        if (callback && typeof callback === 'function') {
            if (isBrowserIeOrNot() !== 'ie') {
                document.addEventListener("DOMContentLoaded", function () {
                    return callback();
                });
            } else {
                document.attachEvent("onreadystatechange", function () {
                    if (document.readyState === "complete") {
                        return callback();
                    }
                });
            }
        } else {
            console.error('The callback is not a function!');
        }
    }

    return domIsReady;
})(domIsReady || {});


(function (document, window, domIsReady, undefined) {
    domIsReady(function () {
        // large map used on homepage and museum index page
        if ($('#dm-map').length > 0) {
            $.ajax({
                url: 'https://maps.googleapis.com/maps/api/js',
                type: "get",
                dataType: "script",
                data: {
                    'key': $("#dm-map").data("key"),
                    'callback': $("#dm-map").data("callback"),
                    'libraries': $("#dm-map").data("libraries"),
                }
            });
        }

        // small map used on museum page
        if ($('#map_canvas').length > 0) {
            $.ajax({
                url: 'https://maps.googleapis.com/maps/api/js',
                type: "get",
                dataType: "script",
                data: {
                    'key': $("#map_canvas").data("key"),
                    'callback': $("#map_canvas").data("callback"),
                    'libraries': $("#map_canvas").data("libraries"),
                }
            });
        }
    });
})(document, window, domIsReady);

initMuseumMap = () => {
    const myOptions = {
        center: {
            lat: parseFloat($("#map_canvas").data("latitude")),
            lng: parseFloat($("#map_canvas").data("longitude")),
        },
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        styles: drentseMuseaMapStyles
    };

    let map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);
    let marker = new google.maps.Marker({ position: myOptions.center, map: map });
};


// Initialize the map on the gooogle maps api js callback.
initDrentseMuseaMap = () => {
    // Set defaults
    const map = new google.maps.Map(document.querySelector('#dm-map'), {
        zoom: 9, //parseInt($("#dm-map").data("zoom")),
        center: {
            lat: 52.99667, //parseFloat($("#dm-map").data("latitude")),
            lng: 6.5625 //parseFloat($("#dm-map").data("longitude")),
        },
        mapTypeControl: $("#dm-map").data("map-type-control"),
        streetViewControl: $("#dm-map").data("street-view-control"),
        disableDefaultUI: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: drentseMuseaMapStyles
    });

    // Create an infowindow object.
    var infowindow = new google.maps.InfoWindow({});


    let locationDataFeatures = [];

    // adjust default map icon
    map.data.addListener('addfeature', function (event) {
        map.data.overrideStyle(event.feature, {
            icon: museum_marker
        });
    });

    // Kadastrale grenzen Drenthe hier. Onderstaande is niet ok.
    map.data.loadGeoJson(`${static_url}geojson/provinces.geojson`);

    map.data.loadGeoJson(
        $("#dm-map").data("geojson-url"),
        { icon: museum_marker },
        features => {

            locationDataFeatures.forEach(dataFeature => {
                map.data.remove(dataFeature);
            });

            locationDataFeatures = features;
            if ($("#dm-map").data("show-list") == "True") {
                updateList(locationDataFeatures);
            }

            //markers = features.map(function (feature) {
            //    return new google.maps.Marker({
            //        position: feature.getGeometry().get(0)
            //    });
            //});

            // create a new marker cluster
            //var markerCluster = new MarkerClusterer(map, markers, {
            //    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
            //    gridSize: 10,
            //    zoomOnClick: false
            //});
        }
    );

    // Listener to update the info window when a marker is clicked.
    map.data.addListener('click', ev => {
        const f = ev.feature;
        infowindow.setContent(f.getProperty('pin_description'));
        infowindow.setPosition(f.getGeometry().get());
        infowindow.setOptions({
            pixelOffset: new google.maps.Size(0, -30)
        });
        infowindow.open(map);
    });

    // Updates the list to the side of the map with markers that are in the viewport.
    function updateList(features) {
        new_html = "";
        if (features.length == 0) {
            $("#LocationList").hide();
            $("#LocationListEmpty").show();
        } else {
            $("#LocationList").show();
            $("#LocationListEmpty").hide();
            for (i = 0; i < features.length; i++) {
                feature = features[i];
                new_html += feature.getProperty('list_description');
            }
        }
        $("#LocationList").html(new_html);
    }
}
